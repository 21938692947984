import React, { useState, useRef, useEffect } from 'react';
import './App_footer.css';
import Cookies from 'js-cookie';
import ButtonWithImageboder from '../components/ButtonWithImageboder';
export default function Footer() {
 
        const [location, setLocation] = useState({
          lat: Cookies.get("lat") || null,
          lon: Cookies.get("lon") || null,
          address: Cookies.get("address") || "Fetching...",
          city: Cookies.get("city") || "",
          state: Cookies.get("state") || "",
          country: Cookies.get("country") || "",
          zipcode: Cookies.get("zipcode") || ""
        });
      
        useEffect(() => {
          if (!navigator.geolocation) {
            console.error("Geolocation is not supported.");
            return;
          }
      
          const geoWatcher = navigator.geolocation.watchPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;
      
              const apiKey = "AIzaSyBBrDds_05fvxejfzmm5gm4ywDQIlEO6E4"; // Replace with your actual API Key
              const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
      
              try {
                const response = await fetch(geocodeUrl);
                const data = await response.json();
      
                if (data.results.length > 0) {
                  const addressComponents = data.results[0].address_components;
                  const formattedAddress = data.results[0].formatted_address;
      
                  let city = "";
                  let state = "";
                  let country = "";
                  let zipcode = "";
      
                  addressComponents.forEach((component) => {
                    if (component.types.includes("locality")) city = component.long_name;
                    if (component.types.includes("administrative_area_level_1")) state = component.long_name;
                    if (component.types.includes("country")) country = component.long_name;
                    if (component.types.includes("postal_code")) zipcode = component.long_name;
                  });
      
                  const addressData = {
                    lat: latitude,
                    lon: longitude,
                    address: formattedAddress,
                    city,
                    state,
                    country,
                    zipcode
                  };
      
                  // Update state
                  setLocation(addressData);
      
                  // Save to cookies
                  Object.entries(addressData).forEach(([key, value]) => {
                    Cookies.set(key, value);
                  });
                }
              } catch (error) {
                console.error("Error fetching geolocation data:", error);
              }
            },
            (error) => console.error("Geolocation error:", error),
            { enableHighAccuracy: true, maximumAge: 0 }
          );
      
          return () => navigator.geolocation.clearWatch(geoWatcher);
        }, []);
 
 

    const year = new Date().getFullYear();   // Dynamic year
 
    const [address, setAddress] = useState(Cookies.get("address") || "Fetching...");

    useEffect(() => { 
      const interval = setInterval(() => {
        setAddress(Cookies.get("address") || "Fetching...");
      }, 5000); // Update every 5 seconds
  
      return () => clearInterval(interval);
    }, []);
  
    return (
        <div>
            <div className="footer_body">
                <div className="container_size">

                    <div className="grid_colm">
                        <div className="title_div">Company</div>

                        <div className="company">
                        <img className="logo_icon" alt="Logo" src={require('../assets/callqr_icon.png')} />
                        CALLQR
                        </div>
                        <ButtonWithImageboder className='download_file'
    title="Submit your Query"
    buttonText="Download App"  
    buttonLink="download"
/>
                    </div>

                    <div className="grid_colm">
                        <div className="title_div">What we do</div>
                        <a className="links_btn" href="features">Features</a> 
                        <a className="links_btn" href="delete-account">Delete Account</a>
                        <a className="links_btn" href="security-advisoroes">Security Advisories</a>
                    </div>

                    <div className="grid_colm">
                        <div className="title_div">What we are</div>
                        <a className="links_btn" href="about-us">About us</a>
                        <a className="links_btn" href="careers">Careers</a>
                        <a className="links_btn" href="for-business">For business</a>
                        <a className="links_btn" href="price">Price</a>
                    </div>

                    <div className="grid_colm">
                        <div className="title_div">Use CallQR</div>
                        <a className="links_btn" href="https://play.google.com/store/apps/details?id=callqr.com">Android App</a>
                        <a className="links_btn" href="/download">CallQR Web</a>
                    </div>

                    <div className="grid_colm">
                        <div className="title_div">Need help?</div>
                        <a className="links_btn" href="contact-us">Contact Us</a>
                        <a className="links_btn" href="help-center">Help Center</a>
                        <a className="links_btn" href="user_guide">User Guide</a>
                        <a className="links_btn">Sale <b>+91-9530621728</b></a>
                    </div>

                    {/* Social Media and Payment Section */}
                    <div className="media_div">
                        <div className="social_link">
                            <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@callqr">
                                <img className="icon" alt="YouTube" src={require('../assets/youtube_icon.png')} />
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.x.com/callqr_">
                            <img className="icon" alt="X (Twitter)" src={require('../assets/x_icon.png')} />
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/callqr">
                            <img className="icon" alt="YouTube" src={require('../assets/Instagram_icon.png')} />
                         
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://whatsapp.com/channel/0029Vaij7nJ6xCSJZ29Wij1s">
                             
                                <img className="icon" alt="YouTube" src={require('../assets/whatsapp_icon.png')} />
                            </a>
                        </div>

                        <div className="payment">
                        <img className="icon" alt="YouTube" src={require('../assets/206684_visa_method_card_payment_icon.png')} />
                        <img className="icon" alt="YouTube" src={require('../assets/1156750_finance_mastercard_payment_icon.png')} />
                        <img className="icon" alt="YouTube" src={require('../assets/upi_payment.png')} />
                        <img className="icon" alt="YouTube" src={require('../assets/india_payment.png')} />
                   
                        </div>
                    </div>

                    {/* Footer Terms Section */}
                    <div className="tearms_div">
                        <div className="container_size">
                            <a id="footer_year_text" className="links_btn">© CallQR.Com - Kang Technology {year} All rights reserved</a>
                            <a className="links_btn" href="privacy-policy">Privacy Policy</a>
                            <a className="links_btn" href="terms-of-service">Terms of Service</a>
                            <a className="links_btn" href="disclaimer">Disclaimer</a>
                            <a className="links_btn" href="faq">FAQ</a> 
                            <a className="links_btn" href="troubleshoot">Trouble shoot</a> 
                            <a className="links_btn" href="sitemap">Sitemap</a> 
                            <a className="links_btn" href="sitemap">Address: {address}</a>
                            <time className="company_links_btn" dateTime="2024-09-01">Updates: 1 April 2025</time>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
