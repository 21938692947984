import React, { useState, useEffect } from 'react';
import { db } from "../firebase"; 
import { collection, getDocs, query, where, limit } from "firebase/firestore"; 
import { Helmet } from "react-helmet"; 
import './price.css';
import photoicon from '../assets/photos_icon.png';
import { useNavigate } from "react-router-dom";
import Lodinging from "../components/lodinging";

export default function Price() {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const q = query(collection(db, "products"), where("status", "==", true));
        const querySnapshot = await getDocs(q);

        const productFetches = querySnapshot.docs.map(async (doc) => {
          const productData = doc.data();
          const puid = productData.puid;
          let productPhoto = "";

          // Fetch one matching photo from slideshow
          const slideshowQuery = query(
            collection(db, "slideshow"),
            where("puid", "==", puid),
            limit(1)
          );
          const slideshowSnap = await getDocs(slideshowQuery);
          if (!slideshowSnap.empty) {
            const photoData = slideshowSnap.docs[0].data();
            if (photoData.photo) {
              productPhoto = photoData.photo;
            }
          }

          return {
            id: doc.id,
            ...productData,
            photo: productPhoto || productData.icon || ""
          };
        });

        const productList = await Promise.all(productFetches);
        setProducts(productList);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProductData();
  }, []);

  const orderClick = (productId) => {
    navigate(`/order?puid=${productId}`);
  };

  return (
    <div className="container_roots">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Free QR Code Generator | CallQR | Create & Scan QR Codes Instantly</title>
        <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Generate free QR codes instantly with CallQR. Create custom QR codes for websites, parking, events, business, and more. Scan any QR, anytime, anywhere. Download the CallQR app now!" />
        <meta name="keywords" content="QR Code Generator, Free QR Codes, Generate QR Online, Custom QR Codes, QR Creator, QR Code for Business, Scan QR Instantly, CallQR App, Parking QR Codes, Event QR Tags, ID Card QR, QR for Membership, QR Code for Website, QR Code for Payments" />
        <meta name="author" content="callqr.com" />
        <meta name="google-play-app" content="app-id=callqr.com" />
        <meta property="og:title" content="Free QR Code Generator | CallQR | Create & Scan QR Codes Instantly" />
        <meta property="og:description" content="Generate free QR codes instantly with CallQR. Create custom QR codes for websites, parking, events, business, and more. Scan any QR, anytime, anywhere. Download the CallQR app now!" />
        <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
        <meta property="og:url" content="https://callqr.com/price" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="CallQR" />
        <meta property="og:dateModified" content="2025-04-03" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Free QR Code Generator | CallQR" />
        <meta name="twitter:description" content="Create and scan custom QR codes easily with CallQR. Perfect for business, events, websites and more." />
        <meta name="twitter:image" content="https://callqr.com/callqr_icon.png" />
        <meta name="twitter:site" content="@CALLQR_" />
        <meta name="twitter:url" content="https://x.com/CALLQR_" />
        <meta name="theme-color" content="#000000" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-title" content="CallQR" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "MobileApplication",
              "name": "CallQR",
              "operatingSystem": "ANDROID",
              "applicationCategory": "communication",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "250"
              },
              "offers": {
                "@type": "Offer",
                "price": "499",
                "priceCurrency": "INR"
              },
              "url": "https://play.google.com/store/apps/details?id=callqr.com",
              "screenshot": "https://callqr.com/callqr_icon.png",
              "publisher": {
                "@type": "Organization",
                "name": "CallQR"
              }
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "CallQR",
              "url": "https://callqr.com/",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "https://callqr.com/search?q={search_term_string}",
                "query-input": "required name=search_term_string"
              }
            }
          `}
        </script>
      </Helmet>
      <div className="container_size">
      <div className="header-div">
      
        <div className="hd-div">
          <div className="price-header">Our Services & Premium QR Code Plans</div>
          <div className="price-sub-header">Choose the right plan to simplify your business with smart QR solutions.</div>
        </div>
        <div className='help-button' onClick={() => window.location.href = './help-center'}>Help</div>
      </div> 
      
        <div className="card-price-body">
          {products.length > 0 ? (
            products.map(product => (
              <div 
                onClick={() => orderClick(product.id)}
                className="card-price" 
                key={product.id}
              >
                <img
                  className="product-img"
                  title={product.title || "product"}
                  src={product.photo}
                  alt="User"
                  loading="lazy"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = photoicon;
                  }}
                />
                <div className="name-price">{product.name}</div>
                <div className="title-price">{product.title}</div>
                <div className="card-price-amount">Price ₹{product.price}/-</div>
                {product.validity >= 365 ? (
                  <div className="card-validity">Validity: {product.validity / 365} Year</div>
                ) : (
                  <div className="card-validity">Validity: {product.validity} Days</div>
                )}
                <button className="buy-button">Buy Now</button>
              </div>
            ))
          ) : (
            <Lodinging />
          )}
        </div>

        <div className="price-info-section">
          <h2>Other Price & Service Info from CallQR</h2>
          <ul className="info-list">
            <li><a href="/for-business">Business Services - CallQR</a></li>
            <p className="description">
              At CallQR, we specialize in delivering comprehensive solutions for seamless business operations under state-of-the-art systems.
              <b> Our services include managing in/out entries</b>, cashbooks, staff accounts, and tracking cash received by staff to ensure financial transparency and efficiency.<br /><br />
              As a trusted service operator, we provide tailored solutions designed to suit both personal and professional needs, empowering you to focus on growth while we handle the intricacies of your business processes. Our flexible plans adapt to your unique requirements, helping you streamline operations with ease.
            </p>
          </ul>
        </div>
      </div>
    </div>
  );
}
