import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { Helmet } from "react-helmet";
import { db } from "../firebase";
import './order.css'; 
import { MdOutlineAddBox } from "react-icons/md";
import { BsCartPlus } from "react-icons/bs"; 
import { FaRegSquareMinus } from "react-icons/fa6";
export default function Order() {
  const navigate = useNavigate();
  const [isOn, setIsOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [TitleValue, setTitleValue] = useState("");
  const [amounWords, setamounWords] = useState("");
  const [MrpValue, setMrpValue] = useState(0);
  const [totalValue, settotalValue] = useState(0);
  const [storageValue, setStorageValue] = useState([]);
  const [PriceValue, setPriceValue] = useState(0);
  const [ValidityValue, setValidityValue] = useState("");
  const [DescriptionValue, setDescriptionValue] = useState("");
  const [productPhotos, setProductPhotos] = useState([]);

  const [searchParams] = useSearchParams();
  const puid = searchParams.get("puid");
  const [Orderqty, setOrderqty] = useState(0);
  const [minorder, setminorder] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef(null);
  function convertFromBytes(bytes) {
    const kb = bytes / 1024;
    const mb = kb / 1024;
    const gb = mb / 1024;
    const tb = gb / 1024;
  
    if (tb >= 1) return `${tb.toFixed(2)} TB`;
    if (gb >= 1) return `${gb.toFixed(2)} GB`;
    if (mb >= 1) return `${mb.toFixed(2)} MB`;
    if (kb >= 1) return `${kb.toFixed(2)} KB`;
    return `${bytes} Bytes`;
  }
  useEffect(() => {
    if (!puid) return;

    const fetchProduct = async () => {
      try {
        const docRef = doc(db, "products", puid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setTitleValue(data.title || "No Title");
          setPriceValue(data.price || "0");
          settotalValue(data.price*data.min_order);
          setamounWords(numberToWords(data.price*data.min_order));
          setOrderqty(data.min_order);
          setminorder(data.min_order);
          setMrpValue(data.mrp);
        const converted = convertFromBytes(100000000);
        setStorageValue(converted);
          if(data.validity>365)
          { setValidityValue(
            data.validity && data.validity_liable
              ? `${data.validity/365}  Year`
              : "No validity info"
          );}else{
          setValidityValue(
            data.validity && data.validity_liable
              ? `${data.validity} ${data.validity_liable}`
              : "No validity info"
          );
        }
          setDescriptionValue(data.description || "No description available");
          document.title = `${data.title} | Purchase Order`;
        } else {
          setDescriptionValue("No product data found");
        }
      } catch (error) {
        console.error("Error fetching product:", error);
        setDescriptionValue("Error loading product details");
      }
    };

    fetchProduct();
  }, [puid]);



  const addorderqty = () => {
    setOrderqty(prevQty => prevQty + 1);
    settotalValue(PriceValue*Orderqty);
    setamounWords(numberToWords(totalValue));
  };
  const minorderqty = () => {
    setOrderqty(prevQty => {
      if (prevQty > minorder) {
        return prevQty - 1;
      }
      return prevQty;
    });
    settotalValue(PriceValue*Orderqty);
    setamounWords(numberToWords(totalValue));
  };
  useEffect(() => {
    if (!puid) return;

    const fetchPhotos = async () => {
      try {
        const photosQuery = query(
          collection(db, "slideshow"),
          where("puid", "==", puid)
        );
        const snapshot = await getDocs(photosQuery);
        const photos = [];

        snapshot.forEach(doc => {
          const data = doc.data();
          if (data.photo) {
            photos.push(data.photo);
          }
        });

        setProductPhotos(photos);
      } catch (error) {
        console.error("Error fetching slideshow photos:", error);
      }
    };

    fetchPhotos();
  }, [puid]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % productPhotos.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? productPhotos.length - 1 : prevIndex - 1
    );
  };

  const startSlideshow = useCallback(() => {
    if (intervalRef.current || productPhotos.length <= 1) return;
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % productPhotos.length);
    }, 3000);
  }, [productPhotos]);

  const stopSlideshow = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  useEffect(() => {
    startSlideshow();
    return () => stopSlideshow();
  }, [startSlideshow, stopSlideshow]);

  if (!productPhotos || productPhotos.length === 0) {
    return <div className="text-gray-500">No photos available</div>;
  }
  function numberToWords(num) {
    const a = [
      '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine',
      'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen',
      'Seventeen', 'Eighteen', 'Nineteen'
    ];
    const b = [
      '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
    ];
  
    function inWords(n) {
      if (n < 20) return a[n];
      if (n < 100) return b[Math.floor(n / 10)] + (n % 10 !== 0 ? ' ' + a[n % 10] : '');
      if (n < 1000) return a[Math.floor(n / 100)] + ' Hundred' + (n % 100 !== 0 ? ' ' + inWords(n % 100) : '');
      if (n < 100000) return inWords(Math.floor(n / 1000)) + ' Thousand' + (n % 1000 !== 0 ? ' ' + inWords(n % 1000) : '');
      if (n < 10000000) return inWords(Math.floor(n / 100000)) + ' Lakh' + (n % 100000 !== 0 ? ' ' + inWords(n % 100000) : '');
      return inWords(Math.floor(n / 10000000)) + ' Crore' + (n % 10000000 !== 0 ? ' ' + inWords(n % 10000000) : '');
    }
  
    return num === 0 ? 'Zero' : inWords(num);
  }
  
  return (
    <div className="container_roots">
      <Helmet>
        <title>Order Custom QR Codes | CallQR</title>
        <meta name="description" content="Easily order customized QR codes for your business, events, parking, menus, and more. Get high-quality, ready-to-use QR designs from CallQR." />
        <meta name="keywords" content="Order QR Code, Custom QR Code, Buy QR Code, Business QR Order, QR Code Printing, Event QR Code, Parking QR Tag, Menu QR Code, CallQR Order" />
        <meta name="author" content="callqr.com" />
        <meta property="og:title" content="Order Custom QR Codes | CallQR" />
        <meta property="og:description" content="Easily order custom QR codes for your needs. Business, event, or parking QR solutions delivered quickly." />
        <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
        <meta property="og:url" content="https://callqr.com/order" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="CallQR" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Order Custom QR Codes | CallQR" />
        <meta name="twitter:description" content="Order high-quality custom QR codes for your business or personal use with CallQR." />
        <meta name="twitter:image" content="https://callqr.com/callqr_icon.png" />
        <meta name="twitter:site" content="@CALLQR_" />
        <meta name="twitter:url" content="https://x.com/CALLQR_" />
        <meta name="google-play-app" content="app-id=callqr.com" />
        <meta name="theme-color" content="#000000" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-title" content="CallQR" />
      </Helmet>

      <div className="container_size">
      <div className="header-div">
      
      <div className="hd-div">
        <div className="price-header">Our Services & Premium QR Code Plans</div>
        <div className="price-sub-header">Choose the right plan to simplify your business with smart QR solutions.</div>
      </div>
      <div className='help-button' onClick={() => window.location.href = './help-center'}>Help</div>
    </div> 
        <div className="amazon-order-container">
          <div className="amazon-order-left">
            <div
              className="relative-order-left"
              onMouseEnter={stopSlideshow}
              onMouseLeave={startSlideshow}
            >
              <img
                src={productPhotos[currentIndex]}
                alt={`Product ${currentIndex + 1}`}
                className="photo-img"
              />
              {productPhotos.length > 1 && (
                <div className="nav_div">
                  <button
                    onClick={handlePrev}
                    aria-label="Previous image"
                    className="Previous-btn absolute left-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white px-3 py-1 rounded-l focus:outline-none"
                  >
                    ‹
                  </button>
                  <button
                    onClick={handleNext}
                    aria-label="Next image"
                    className="Next-btn absolute right-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white px-3 py-1 rounded-r focus:outline-none"
                  >
                    ›
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="amazon-order-right">
            <div className="product-title">{TitleValue}</div>
            <div className="product-price">₹{PriceValue}/- INR</div>
            <div className="order-options">
           
                <div className="order-qty-select"> 
                <FaRegSquareMinus onClick={minorderqty} className='qty_btn' />
                <div>{Orderqty}</div>
                <MdOutlineAddBox onClick={addorderqty} className='qty_btn' />
                </div>
            <table > 
              <tr>
                <td>M.R.P</td>
                <td className="order-product-table"><b>₹{MrpValue}/-</b></td>
              </tr>
              <tr>
                <td>Storage</td>
                <td className="order-product-table">
              <b>
                {storageValue}
              </b>
            </td>
              </tr>
              <tr>
                <td>Validity</td>
                <td className="order-product-table"><b>{ValidityValue}</b></td>
              </tr>
              <tr>
                <td>Total Amount INR</td>
                <td className="order-product-table"><b>₹{totalValue}/-</b></td>
              </tr>
            </table>
            <a className="order-product-table">{amounWords}</a>
              <button className="buy-button">Buy Now</button>
              </div>
            </div>
         
        </div>
     
      <div className="product-description">
        <div className='product-description-hd'>
          <b>Product Description</b>
          <div>ID: {puid}</div>
        </div>
        <br />
        <div dangerouslySetInnerHTML={{ __html: DescriptionValue }}></div>
      </div>
    </div>
    </div>

  );
}
