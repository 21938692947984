import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { db } from "../firebase";
import Logo from '../assets/callqr_logo.png';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { IoIosLogIn } from "react-icons/io";
import { doc, getDoc } from "firebase/firestore";
import { Helmet } from "react-helmet";
import './settings.css';
import EasyImageUploader from '../components/EasyImageUploader';
import Buttontheme from '../components/Buttontheme';
import Lodinging from "../components/lodinging";  
import { MdOutlineManageAccounts,MdOutlineAccountBalance} from "react-icons/md"; 
export default function Settings() {
    const [selected, setSelected] = useState('Profile');
  const [accountguid, setAccount] = useState("");
  const [accountname, setAccountName] = useState("User");
  const [gender, setGender] = useState("Gender");
  const [photourl, setUserPhotoUrl] = useState("/default-profile.png");
  const [phone, setPhone] = useState("Phone");
  const [email, setEmail] = useState("Not updated");
  const [address, setAddress] = useState("Address");
  const [bloodgroup, setBloodGroup] = useState("Not selected");
  const [country, setCountry] = useState("Country");
  const [datetime, setDatetime] = useState("Create");
    const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  //login 
   const [islogin, setislogin] = useState(false);
   const [guid, setguid] = useState('0');
   const [aguid, setaguid] = useState('0');
 useEffect(() => {
  const fetchUserData = async () => {
    const existingUser = Cookies.get("guid");
    if (existingUser) {
      setislogin(true);
      setguid(existingUser);
      setaguid(Cookies.get("aguid"));
    } else {
      
      setguid("0");
      setislogin(false);
      navigate("/");
    }
  };
  fetchUserData();
}, []);
  const accountLogout = async () => {
 
    setLoading(true);
    try { 
      Cookies.remove("guid", { path: "/" });
      Cookies.remove("loginkey", { path: "/" });
   
     navigate("/");
     setTimeout(() => {
      window.location.reload(); // Reloads the page after OTP verification
  }, 2000);  
    } catch (error) {
      console.error("❌ Logout Error:", error);
    } finally {
      setLoading(false);  // Ensure loading state resets
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const existingUser = Cookies.get("guid");
      if (existingUser) {
        try {
          const userRef = doc(db, "account", existingUser);
          const docSnap = await getDoc(userRef);

          if (docSnap.exists()) {
            const data = docSnap.data();
            setAccountName(data.name || "User");  
            setGender(data.gender || "Gender");  
            setUserPhotoUrl(data.photo || "/default-profile.png");
            setPhone(data.phone ? `+${data.phone}` : "Phone");
            setEmail(data.email || "Not updated");
            setCountry(data.country || "Country");
            setAddress(data.address || "Address");
            setBloodGroup(data.blood_group || "Not selected");
            setDatetime(data.date_time ? data.date_time.toDate().toLocaleString() : "Create");
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }else{
        
       // accountLogout();
      }
    };
    fetchUserData();
  }, []);

  return (
    <div className="container_roots">
      <Helmet>
        <title>Your Profile Settings - CallQR</title>
        <meta name="description" content="Manage your CallQR profile." />
        <meta property="og:title" content="Your Profile Settings - CallQR" />
      </Helmet>
<div className='master-menu'>
<div className="button-body">
                  <div
    onClick={() => setSelected('Profile')}
    className={`master-menu-button ${selected === 'Profile' ? 'active' : ''}`}
    title="Profile"
  >
    <MdOutlineManageAccounts e className="menubutton-icon" />
    <span className="button-text">Profile</span>
  </div>
  <div
    onClick={() => setSelected('Balance')}
    className={`master-menu-button ${selected === 'Balance' ? 'active' : ''}`}
    title="Balance"
  >
    <MdOutlineAccountBalance e className="menubutton-icon" />
    <span className="button-text">Balance</span>
  </div>
 
  <div className="orders-div">
  <div
    onClick={() => setSelected('Log')}
    className={`master-menu-button ${selected === 'Log' ? 'active' : ''}`}
    title="Login History"
  >
    <IoIosLogIn e className="menubutton-icon" />
    <span className="button-text">Log</span>
  </div> </div>
  </div>
</div>
{selected === 'Profile' && (
      <div className="settings_root">
        <h2>Your Profile Info</h2>
        <p>Manage your personal details.</p>

        <div className="title-text">Your Information Summary</div>
        <div className="div_settings">
          <div className='photo_div'>
            <img 
              onClick={() => EasyImageUploader()}
              className="photo_settings" 
              title={accountname}  
              src={photourl} 
              alt="User Profile" 
            />
          </div>
      
          <table className='table_settings'> 
            <tbody>
              {[{"label": "Name", "value": accountname},
                {"label": "Gender", "value": gender},
                {"label": "Phone Number", "value": phone},
                {"label": "Email", "value": email},
                {"label": "Country", "value": country},
                {"label": "Address", "value": address},
                {"label": "Blood Group", "value": bloodgroup},
                {"label": "Account GUID", "value": Cookies.get("guid") || "GUID"},
                {"label": "Create Account", "value": datetime}
              ].map((item, index) => (
                <tr key={index}>
                  <td className='user-title-text'><div>{item.label}</div></td>
                  <td className='name_tabel'>{item.value}</td>
                </tr>
              ))}
              <tr>
                <td className='user-title-text'><div>Logout</div></td>
                <td className='name_tabel'>               
                <Buttontheme 
  onClick={accountLogout} 
  title="Logout now" 
  buttonText="Logout"  
/>
                </td>
              </tr>
            </tbody>
          </table>
         
        </div>
        <div><b>Note:</b> Log in to update your information.</div>
      </div>
       )}
    {selected === 'Balance' && (
      <div className="settings_root">
              <h2>Your Account Balance Info</h2> 
      </div>
    )}
      {selected === 'Log' && (
      <div className="settings_root">
              <h2>Your Account Login Log</h2> 
      </div>
    )}
    </div>
  );
}
