import { useEffect, useState } from "react";
import { Short_url_history } from "../Short_url_history";
import Cookies from 'js-cookie';
export function Shorturlredirect({ data }) {
  const [ipAddress, setIpAddress] = useState("1.1.1");
  const [address, setAddress] = useState("Fetching...");

  useEffect(() => {
    const savedAddress = Cookies.get("address");
    if (savedAddress) {
      setAddress(savedAddress);
    }
  }, []);
  // Generate Unique ID
  const generatedStrings = new Set();
  function generateUniqueRandomString(length = 10) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result;
    do {
      result = '';
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
    } while (generatedStrings.has(result));
    generatedStrings.add(result);
    return result;
  }

  // Fetch IP once
  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((res) => res.json())
      .then((ipData) => {
        console.log("Fetched IP:", ipData.ip);
        setIpAddress(ipData.ip);
      })
      .catch((err) => {
        console.error("IP fetch error:", err);
        setIpAddress("0.0.0.0"); // fallback
      });
  }, []);

  // Handle redirection and tracking
  useEffect(() => {
 
    if (!data?.longurl) {
      console.warn("Missing data.longurl, aborting redirect.");
      return;
    }   
    const redirectToLongUrl = () => {         
        console.log("CallQR.Com from Redirecting to  :", data.longurl);
         window.location.href = data.longurl;     
        //window.location.replace(data.longurl);
    };
    const handleRedirect = async () => {
      const trxid = generateUniqueRandomString();
      try {
        console.log("Sending tracking data to Short_url_history...");
        await Short_url_history({
          trxid,
          longurl: data.longurl,
          shorturl: data.shorturl,
          alias: data.alias || "",
          uid: data.uid,
          address:address,
          device: navigator.userAgent, 
          ip: ipAddress,
        });
        console.log("Short_url_history success.");
      } catch (err) {
        console.error("Short_url_history error:", err);
      }
      redirectToLongUrl();
    };

    if (data.premium === false) {
      console.log("Free user, redirecting immediately.");
      redirectToLongUrl();
    } else if (data.premium === true) {
      console.log("Premium user, sending history and redirecting.");
      handleRedirect();
    }
  }, [data]);

  return null;
}
