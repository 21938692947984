import './App.css';
import { Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import { appRoutes } from './routes';
import Cookies from "js-cookie";
import Header from './body_roots/header';
import Footer from './body_roots/footer';
import Home from './home_pages/home'; 
import WhatsAppQueryPopup from './components/WhatsAppQueryPopup'; 
 
function App() {
 
  return (
    <div className="App">
   
      <Header />
      <WhatsAppQueryPopup />
      <div className='app_header_height'></div>
      <Routes>
  {appRoutes.map(({ path, element }, i) => (
    <Route key={i} path={path} element={element} />
  ))}
  <Route path="/:id" element={<Home />} />
</Routes>
      <Footer />
    </div>
  );
}

export default App;
