import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { doc, query, where, getDocs, setDoc, getDoc, collection } from "firebase/firestore";
import './user_data.css';
import { MdOutlineVerifiedUser } from "react-icons/md";
import { BsGenderAmbiguous } from "react-icons/bs";
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import { IoLocationOutline } from "react-icons/io5";
import { HiOutlineCalendarDateRange } from "react-icons/hi2";
import { MdOutlineSupervisorAccount } from "react-icons/md";
import { IoShareSocial } from "react-icons/io5";
import { TiSocialFacebook } from "react-icons/ti";
import { TiSocialInstagram } from "react-icons/ti";
import { SlSocialYoutube } from "react-icons/sl";
import { SlSocialPintarest } from "react-icons/sl";
import { SlSocialLinkedin } from "react-icons/sl";
import { SlSocialTumblr } from "react-icons/sl";
import { FaPhone } from "react-icons/fa6";
import Lodinging from "../components/lodinging";
import photoicon from  '../assets/photos_icon.png';
import id_card from '../assets/id_card_100dp.png';
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa6";
export default function Userdata({ data }) {
  const [docData, setDocData] = useState(null);
  const [qrdata, setqrata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
 const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      if (data?.guid) {
        try {
          //search accounts_manage        
          const accounts_manageCollection = collection(db, "accounts_manage");
          const q = query(
            accounts_manageCollection, 
            where("callqruid", "==", data?.uid), 
            where("status", "==", true)
          );          
          const querySnapshot = await getDocs(q);          
          if (!querySnapshot.empty) {
           // accounts_manage
            const firstDoc = querySnapshot.docs[0]; // Get first document
            const account_Doc = firstDoc.data(); // Extract data
                const docRef = doc(db, "account", account_Doc?.guid);
          const docSnap = await getDoc(docRef);     
          if (docSnap.exists()) {
            setDocData(docSnap.data());
            console.log("Document exists:", docSnap.data());
          } else {
            console.log("Document does not exist.");
            setDocData(null);
          }
         }else{ 
   // admin account 
   const docRef = doc(db, "account", data?.guid);
   const docSnap = await getDoc(docRef);     
   if (docSnap.exists()) {
 
     setDocData(docSnap.data());
     console.log("Document exists:", docSnap.data());
   } else {
     console.log("Document does not exist.");
     setDocData(null);
   } 
         }
      
        } catch (error) {
          console.error("Error fetching document:", error);
          setError(true);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false); // No GUID, stop loading
      }
    };

    fetchData();

  }, [data?.guid]);

  if (!data) {
    return (
      <div className="container_roots">
        <h2>No User Data Found</h2>
      </div>
    );
  }  
  const share_btn = () => {
    const currentUrl = window.location.href;

    if (navigator.share) {
        navigator.share({
            title: document.title,
            url: currentUrl
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
        // Fallback for browsers that do not support Web Share API
        const tempInput = document.createElement("input");
        tempInput.value = currentUrl;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        alert("URL copied to clipboard!");
    }
};
const phone_call_btn = () => {
  window.location.href = `tel:+${docData?.phone}`;
};
const Whatsapp_btn = () =>{
  const text = encodeURIComponent(
    `Callqr.com Scanned Query:\nCallQR: ${data?.name}\nName: ${docData?.name}\nPhone: +${docData?.phone}\nMessage: Hi, I just scanned your QR code and I need to chat with you. It's important `
  );
 
  const whatsappNumber = docData?.phone; // Replace with your number
  const url = `https://wa.me/${whatsappNumber}?text=${text}`;
 
  window.open(url, '_blank');
}
 
  return (
    <div className="container_roots">
      {loading ? (
       <Lodinging/>
      ) : error ? (
        <p>Error fetching data.</p>
      ) : (
        
        <div className="container_card">
        <div 
    className="cover_qr">
            <div className="photo-div">
            <img
  className="cover-photo-img"
  src={data?.icon}
  alt="User"
  onError={(e) => {
    e.target.onerror = null; // prevent infinite loop
    e.target.src = id_card;
  }}
/>
             
            </div>
            <div className="name-div">
              <div className="name-text">{data?.name}</div>
              <div className="status-text">{data?.description_text}</div>
             
            </div>
          </div>
          <div className="user-card">
            <div className="photo-div"> 

            <img
          className="photo-img"
          src={docData?.photo}
          alt="User"
          onError={(e) => {
            e.target.onerror = null; // prevent infinite loop
            e.target.src = photoicon;
          }}
        />

              <MdOutlineVerifiedUser className="Verified" />
            </div>
            <div className="name-div">
              <div className="name-text">{docData?.name}</div>
              <div className="status-text">{docData?.about}</div>
             
            </div>
          </div>
          <div className="user-card">
          <div onClick={phone_call_btn} className="phone-div">
              <FaPhone className="icon phone-icon" /><b className="phone-text">+{docData?.phone}</b>
              </div>
              <div className="chat-div">
              <IoChatboxEllipsesOutline className="icon chat-icon" /><b className="chat-text">Chat with us</b>
              </div>
              </div>
          <div className="user-card">
            <div className="gender-div" >
              <BsGenderAmbiguous className="icon" />
              <div className="gender-text">{docData?.gender}</div>
            </div>
            <div className="dob-div" >
              <LiaBirthdayCakeSolid className="icon" />
              <div className="gender-text">
                {docData?.date_of_birth?.toDate().toLocaleDateString('en-GB') ?? "N/A"}
              </div>
            </div>
          </div>
         
         
          <div className="user-card">
            <div className="row_verifed" >
              <MdOutlineSupervisorAccount className="icon" />
              <div className="verifed-text">
                <b></b> Verifed
              </div>
            </div>
            <div className="row_fill" > 
              <div className="joined-text">Joined <b>
                {docData?.date_time?.toDate().toLocaleDateString('en-GB') ?? "N/A"}</b>
              </div>
            </div>

          </div>
          <div className="user-card">
          
                <IoLocationOutline className="icon adds-icon" />
                <div className="address-text">
                  {docData?.address}</div>
            
          </div>
          <div className="media-card">
          {/* <TiSocialFacebook className="media-link" /> 
          <TiSocialInstagram className="media-link" /> 
          <SlSocialYoutube className="media-link" />  
          <SlSocialLinkedin  className="media-link" /> 
          <SlSocialPintarest  className="media-link" />    
       
          <SlSocialTumblr  className="media-link" />    */}
          <FaWhatsapp onClick={Whatsapp_btn} className="media-link" />            
          <IoShareSocial onClick={share_btn} className="media-link" />  
          </div>
        </div>
      )}
    </div>
  );
}
